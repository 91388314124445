.content-card {
    max-width: 100%;
    margin: 10px auto;
}
.card-body {
    text-align: center;
}

.th {
    text-align: center;
}