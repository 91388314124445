.users-card.content-card {
    max-width: 100%;
    min-width: 80vw !important;
    margin: 10px auto;
}

.card-body {
    text-align: center;
}

.th {
    text-align: center;
}

