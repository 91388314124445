.loading {
    position: absolute;
    z-index: 100000;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background: rgba(0,0,0,.2);
}
.loading-content {
    margin-top: 43vh;
}