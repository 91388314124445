
.view-queue-modal {
    min-width: 95vw;
    min-height: 75vh;
}


.custom-fields-modal {
    text-align: center;
    margin-top: 100px;
}

.custom-fields-modal-body {
    text-align: center;
}


 .custom-dropdown:focus {
     border-color: #ff80ff;
     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(255, 100, 255, 0.5);
 }
