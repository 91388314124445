.dropzone {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top:20px;
}

.container {
    display: grid;
    height: 100vh;
    min-height: 60vh;
    max-height: 80vh;
    min-width: 80vw;
    max-width: 100vw;
    margin: 0;
    place-items: center center;
}

.fileUploadRow {
    display: grid;
    height: 50vh;
    width: 80vw;
    place-items: center center;
}