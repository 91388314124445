
/*
.custom-fields-modal {
    width: 250px;
    height: 300px;
}
*/

.custom-fields-modal-body {
    text-align: center;
}


