
.account-search-modal {
    min-width: 95vw;
    min-height: 75vh;
}

.account-search-input {
    text-align: center;
}

.custom-fields-modal {
    text-align: center;
    margin-top: 100px;
}

.custom-fields-modal-body {
    text-align: center;
}
