.App {
/*  text-align: center;*/
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-table {
  padding: 1rem;

table {
  border-spacing: 0;
  border: 1px solid black;

tr {
:last-child {
td {
  border-bottom: 0;
}
}
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

:last-child {
  border-right: 0;
}
}
}
}

.container {
  margin-top: 50px;
}

.container-fluid {
  margin-top: 50px;
}

.big {
  font-size: 1.2em;
}

.small {
  font-size: .7em;
}

.square {
  width: .7em;
  height: .7em;
  margin: .5em;
  display: inline-block;
}

/* Custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.custom-dropdown select {
  background-color: #ffffff;
  color: #404040;
  font-size: inherit;
  padding: .5em;
  padding-right: 2.5em;
  border: 0;
  margin: 0;
  border: 1px solid #404040;
  border-radius: 3px;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: none!important; /* hide default arrow in chrome OSX */
}

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after { /*  Custom dropdown arrow */
  content: "\25BC";
  height: 1em;
  font-size: .625em;
  line-height: 1;
  right: 1.2em;
  top: 50%;
  margin-top: -.5em;
}

.custom-dropdown::before { /*  Custom dropdown arrow cover */
  width: 2em;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 3px 3px 0;
}

.custom-dropdown select[disabled] {
  color: rgba(0,0,0,.3);
}

.custom-dropdown select[disabled]::after {
  color: rgba(0,0,0,.1);
}

.custom-dropdown::before {
  background-color: rgba(0,0,0,.15);
}

.custom-dropdown::after {
  color: rgba(0,0,0,.4);
}

.rt-tbody {
  overflow: visible !important;
}

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}

.pagination button {
  margin-right: 10px;
  margin-top:10px;
}

.pagination span {
  margin-right: 10px;
  margin-top:15px;
}

.pagination select {
  margin-top:10px!important;
}

/*
.container-outer { overflow: scroll; width: 100vw; height: 700px; }
.container-inner { width: 3000px; }
*/

/*.rt-tbody {
  max-height: 550px;
}*/


.rt-resizable-header-content::after {
   content: " \2195";
  /* float: left;*/
  padding-left: 5px;
}
